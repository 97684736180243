import type { UserModule } from '~/types'

export const install: UserModule = ({ router }) => {
  const authStore = useAuthStore()
  router.beforeResolve(async (to, from, next) => {
    const home = { name: 'index', params: {} }

    if (to.meta.requiresAuth) {
      if (!authStore.isAuthenticated) {
        try {
          await authStore.getUser()
        }
        catch {
          next({ name: 'login', query: { redirect: to.fullPath } })
          return
        }
      }

      if (to.name === 'index' && home.name !== 'index')
        next(home)

      else if (!to.meta.requiresRole || (to.meta.requiresRole && authStore.hasRole(to.meta.requiresRole as string)))
        next()

      else
        next(home)
    }
    else {
      next()
    }
  })
}
