import { axios } from '~/composables/axios'
import type { CustomerManagerCreateForm, CustomerManagerInfo } from '~/models/CustomerManager'

export default class CustomerManagersService {
  static async getCustomerManagers(customer_id: string) {
    const { data } = await axios.get(`/customers/${customer_id}/customer_managers/list`)

    return data
  }

  static async getCustomerManager(customer_id: string, customer_manager_id: string) {
    const { data } = await axios.get(`/customers/${customer_id}/customer_managers/${customer_manager_id}/info`)
    return data
  }

  static async createCustomerManager(customer_id: string, manager: CustomerManagerCreateForm) {
    const { data } = await axios.post(`/customers/${customer_id}/customer_managers/create`, manager)
    return data
  }

  static async updateCustomerManager(customer_id: string, customer_manager_id: string, manager: CustomerManagerInfo) {
    const { data } = await axios.put(`/customers/${customer_id}/customer_managers/${customer_manager_id}/update`, manager)
    return data
  }

  static async deleteCustomerManager(customer_id: string, customer_manager_id: string) {
    const { data } = await axios.delete(`/customers/${customer_id}/customer_managers/${customer_manager_id}/delete`)
    return data
  }
}
