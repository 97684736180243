import { axios } from '~/composables/axios'
import type { SiteManagerCreateForm, SiteManagerInfo } from '~/models/SiteManager'

export default class SiteManagersService {
  static async getSiteManagers(customerId: string, siteId: string) {
    const { data } = await axios.get(`/customers/${customerId}/site_managers/list`, { params: { site_id: siteId } })

    return data
  }

  static async getSiteManager(customerId: string, SiteManagerId: string) {
    const { data } = await axios.get(`/customers/${customerId}/site_managers/${SiteManagerId}/info`)
    return data
  }

  static async createSiteManager(customerId: string, manager: SiteManagerCreateForm) {
    const { data } = await axios.post(`/customers/${customerId}/site_managers/create`, manager)
    return data
  }

  static async updateSiteManager(customerId: string, SiteManagerId: string, manager: SiteManagerInfo) {
    const { data } = await axios.put(`/customers/${customerId}/site_managers/${SiteManagerId}/update`, manager)
    return data
  }

  static async deleteSiteManager(customerId: string, SiteManagerId: string) {
    const { data } = await axios.delete(`/customers/${customerId}/site_managers/${SiteManagerId}/delete`)
    return data
  }
}
