<script setup>
import { usePreferredDark } from '@vueuse/core'

const preferredDark = usePreferredDark()

useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: computed(() => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg'),
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/iphone5_splash.png',
      media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/iphone6_splash.png',
      media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/iphoneplus_splash.png',
      media: '(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/iphonex_splash.png',
      media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/iphonexr_splash.png',
      media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/iphonexsmax_splash.png',
      media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/ipad_splash.png',
      media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/ipadpro1_splash.png',
      media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/ipadpro3_splash.png',
      media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/splash-screens/ipadpro2_splash.png',
      media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)',
    },
  ],
})
</script>

<template>
  <RouterView />
</template>
