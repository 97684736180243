import { axios } from '~/composables/axios'
import type { UserCreateForm, UserUpdateForm } from '~/models/User'

export default class UsersService {
  static async getUsers() {
    const { data } = await axios.get('/administrators/list')
    return data
  }

  static async getUser(id: string) {
    const { data } = await axios.get(`/administrators/${id}/info`)
    return data
  }

  static async createUser(user: UserCreateForm) {
    const { data } = await axios.post('/administrators/create', user)
    return data
  }

  static async updateUser(user: UserUpdateForm) {
    const { data } = await axios.put(`/administrators/${user.id}/update`, user)
    return data
  }

  static async deleteUser(id: string) {
    const { data } = await axios.delete(`/administrators/${id}/delete`)
    return data
  }
}
