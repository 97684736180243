import Axios from 'axios'
import { getCookie, setCookie } from 'typescript-cookie'
import AuthService from '~/services/auth'

interface tokensInterface {
  access_token: string
  refresh_token: string
}

const axios = Axios.create({
  baseURL: String(import.meta.env.VITE_APP_API_URL),
})

axios.interceptors.request.use((config) => {
  const access_token = getCookie('access_token')
  config.headers.Authorization = `Bearer ${access_token}`
  return config
})

let isRefreshing = false

axios.interceptors.response.use(
  response => response,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !isRefreshing) {
      isRefreshing = true

      const refresh_token = getCookie('refresh_token')
      setCookie('access_token', refresh_token, { path: '/', secure: location.protocol === 'https:', sameSite: 'strict' })
      const tokens: tokensInterface = await AuthService.refreshToken()
      isRefreshing = false
      AuthService.saveTokens(tokens)
      return axios(config)
    }
    return Promise.reject(error)
  },
)

export { axios }
