const permissions = {
  SITE_CREATION: ['flovea_manager', 'customer_manager'],
  SITE_EDIT: ['flovea_manager', 'customer_manager'],
  SITE_DELETE: ['flovea_manager', 'customer_manager'],
  SITE_MANAGER_CREATION: ['flovea_manager', 'customer_manager'],
  FLOVEA_CUSTOMER_DELETE: ['flovea_manager'],
  EMAIL_NOTIFICATION: ['customer_manager', 'site_manager'],
  KONAMI: ['flovea_manager'],
  DASHBOARD_VIEW_CUSTOMERS: ['flovea_manager'],
  DASHBOARD_VIEW_SITES: ['flovea_manager', 'customer_manager'],
  ABNORMAL_CONSUMPTION_THRESHOLDS: ['flovea_manager'],
}

export { permissions }
