<script setup>
const authStore = useAuthStore()
const router = useRouter()

if (authStore.user.role === 'flovea_manager')
  router.push({ name: 'customers' })
else if (authStore.user.role === 'customer_manager')
  router.push({ name: 'customers-customerId', params: { customerId: authStore.user.customer_id } })
else if (authStore.user.role === 'site_manager')
  router.push({ name: 'customers-customerId-site-siteId', params: { customerId: authStore.user.customer_id, siteId: authStore.user.site_id[0] } })
else
  router.push({ name: 'login' })
</script>

<route lang="yaml">
meta:
  requiresAuth: true
  title: dashboard
  icon: dashboard
</route>

<template>
  &nbsp;
</template>
