import { setCookie } from 'typescript-cookie'
import { axios } from '~/composables/axios'
import type { Registration } from '~/models/Auth'

export default class AuthService {
  static async saveTokens(tokens: { access_token: string, refresh_token: string }) {
    setCookie('access_token', tokens.access_token, { path: '/', secure: location.protocol === 'https:', sameSite: 'strict' })
    setCookie('refresh_token', tokens.refresh_token, { path: '/', secure: location.protocol === 'https:', sameSite: 'strict' })
  }

  static async login(email: string, password: string) {
    const { data } = await axios.post('/users/token', { email, password })
    return data
  }

  static async register(registration: Registration) {
    const { data } = await axios.post('/users/register', { ...registration })
    return data
  }

  static async resetPasswordRequest(email: string) {
    const { data } = await axios.post('/users/request_reset_password', { email })
    return data
  }

  static async setPassword(token: string, password: string) {
    const { data } = await axios.post('/users/set_password', { token, password })
    return data
  }

  static async resetPassword(token: string, password: string) {
    const { data } = await axios.post('/users/reset_password', { token, password })
    return data
  }

  static async refreshToken() {
    const { data } = await axios.post('/users/refresh')
    return data
  }

  static async getUser() {
    const { data } = await axios.get('/users/me')
    return data
  }

  static async updatePassword(currentPassword: string, password: string) {
    await axios.put('/users/update_password', { password, current_password: currentPassword })
  }
}
