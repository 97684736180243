import { roles } from '~/composables/roles'

export class User {
  id: string
  email: string
  firstname: string
  lastname: string
  last_logged_in: string
  language: string
  customer_id: string
  site_id: string[]
  role: string
  alert_email_notification_enabled: boolean

  constructor({
    id = '',
    email = '',
    firstname = '',
    lastname = '',
    language = '',
    customer_id = '',
    site_id = [],
    role = -1,
    alert_email_notification_enabled = false,
    last_logged_in = '',
  } = {}) {
    this.id = id
    this.email = email
    this.firstname = firstname
    this.lastname = lastname
    this.last_logged_in = last_logged_in
    this.language = language
    this.customer_id = customer_id
    this.site_id = site_id
    this.role = roles[role - 1]
    this.alert_email_notification_enabled = alert_email_notification_enabled
  }
}

export class Registration {
  email: string
  firstname: string
  lastname: string
  address: string
  alert_email_notification_enabled: boolean
  language: string

  constructor({
    email = '',
    firstname = '',
    lastname = '',
    address = '',
    alert_email_notification_enabled = false,
    language = 'en',
  } = {}) {
    this.email = email
    this.firstname = firstname
    this.lastname = lastname
    this.address = address
    this.alert_email_notification_enabled = alert_email_notification_enabled
    this.language = language
  }
}
